<template>
  <div id="app">
    <header>
        <NavBar />
        <MobMenu />
    </header>
    <main>
        <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import MobMenu from '@/components/MobMenu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
      NavBar,
      MobMenu,
      Footer
  }
};
</script>

<style>
/*------|DISEÑO GLOBAL|--------*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body{
    background: #212121;
}

#app{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

main{
    padding-top: 80px;
    min-height: 100vh;
}

.container{
    margin: 0 auto;
    max-width: 1440px;
    padding: 32px;
    overflow: hidden;
}

.container.small{
    max-width: 1024px;
}

.loading{
    position: relative;
    overflow: hidden;
    background: #cfcfcf !important;
    animation: loading 1s infinite !important;
}

.loading *{
    display: none;
}

@keyframes loading {
    0% {
        opacity: 1;
    }
    50%   {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.hide-on-large{
    display: none !important;
}

/*--------DISEÑO DE COLUMNAS DE ACUERDO AL CONTENIDO---------------*/

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
}

.col{
    flex-shrink: 0;
}

.l2{
    width: calc(16.66% - 20px);
}

.l2-5{
    width: calc(20% - 19px);
}

.l3{
    width: calc(25% - 18px);
}

.l4{
    width: calc(33% - 18px);
}

.col-4 .img{
    border-radius: 4px;
    padding-top: 56.26%;
    background-color: #666;
}

/*---------TÍTULOS DE SECCIONES---------*/

h1{
    font-size: 36px;
    color: white;
}

h3{
    margin-bottom: 16px;
    margin:16px 0;
    font-size: 18px;
}

.section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.section-header a:link{
    text-decoration: none;
    color: initial;
}

.section-header a:visited{
    text-decoration: none;
    color: initial;
}

.section-title{
    display: flex;
    font-weight: 700;
    font-size: 28px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.section-title::before{
  content: "";
  display: block;
  width: 6px;
  height: 24px;
  background-color: #fcaf17;
  border-radius: 4px;
}

.section-content{
    display: flex;
    flex-wrap: wrap;
}

.container .section-content{
    grid-gap: 24px;
}

.container.small .section-content{
    grid-gap: 31px;
}

.container.small .art-body p{
    margin-bottom: 16px;
}

.container.small .art-body img{
    max-width: 100%;
    height: auto;
}

.container.small .art-body p iframe {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
}

/*----- DISEÑO PREESTABLECIDO SEGUN SECCIÓN -----*/

section{
    background-color: white;
}

section.dark{
    background-color: #212121;
}

section.dark h2, section.dark h3, section.dark p{
    color: white;
}

section.dark:not(:first-child,:last-child){
        margin: 12px 0 0 0;
}

section.dark .container{
    padding-bottom: 20px !important;
}

/*------ETIQUETA DE TIEMPO TRANSCURRIDO O DE FECHA-------*/

.date-tag{
    min-width: 70px;
    min-height: 30px;
    background-color: #fcaf17;
    font-weight: 700;
    font-size: 12px;
    padding: 6px;
    text-transform: uppercase;
    width: max-content;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.swiper-container{
    overflow: initial !important;
}

/*---------------BOTÓN DE REPRODUCCIÓN PARA TRAILERS*/

.play{
    position: absolute;
    width: 40%;
    max-width: 100px;
    max-height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.4));
    -webkit-filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.4));
    -moz-filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.4));
    -ms-filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.4));
    -o-filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.4));
}

.playIcon{
    width: 100%;
    padding-top: 100%;
    background: white;
    mask: url("/assets/icons/play.svg");
    mask-repeat: no-repeat;
}

.swiper-navigation{
    display: flex;
}

.swiper-button-prev, .swiper-button-next{
    position: relative !important;
    color: #666 !important;
    left: initial !important;
    right: initial !important;
    z-index: initial !important;
}

.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 24px !important;
}

.swiper-button-prev{
    margin-right: 16px; 
}

.load-more{
    color: #fcaf17;
    background: black;
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;
    padding: 12px 36px;
    cursor: pointer;
}

.bottom-container{
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: center;
}

/*Compartir*/

.share {
    display: flex;
    align-items: center;
}

.share .social-icon-container{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #fcaf17;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share .social-icon-container .social-icon{
    width: 18px;
    height: 18px;
    background: black;
}

.share .social-icon-container .social-icon.whatsapp{
    mask: url("/assets/icons/whatsapp-brands.svg");
    mask-repeat: no-repeat;
    mask-position: center;
}

.share .social-icon-container .social-icon.twitter{
    mask: url("/assets/icons/twitter.svg");
    mask-repeat: no-repeat;
    mask-position: center;
}

.share .social-icon-container .social-icon.facebook{
    mask: url("/assets/icons/facebook-f-brands.svg");
    mask-repeat: no-repeat;
    mask-position: center;
}

:root .plyr__control--overlaid{
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: none !important;
    border: 8px solid white;
    width: 20vw;
    height: 20vw;
    max-width: 100px;
    max-height: 100px;
}

:root .plyr__control--overlaid svg{
   width: 25%;
   transform: scale(2.5);
}

.dropdown-menu{
    left: initial !important;
    right: 0;
    max-height: 310px;
    overflow: auto;
}

@media (max-width: 768px) {

    .hide-on-large{
        display: inline !important;
    }

    .hide-on-small{
        display: none!important;
    }
    
    .container{
        padding: 20px 8px !important;
    }

    nav{
        height: 56px;
    }

    nav .container{
        padding: 8px !important;
    }    

    .m4{
        width: calc(33% - 4px) !important;
    }

    .m3{
        width: calc(25% - 6px) !important;
    }
    

    main{
        padding-top: 54px;
    }

    /*---------TÍTULOS DE SECCIONES---------*/

    .section-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .swiper-button-next, .swiper-button-prev{
        margin-top: 0 !important;
    }

    .section-title{
        font-weight: 700;
        font-size: 16px;
    }

    .section-title::after{
        width: 60px;
        height: 4px;
        background-color: #fcaf17;
    }

    .section-content{
        grid-gap: 7px !important;
    }

    .section-content .col{
        margin-bottom: 16px;
        margin-right: 0;
    }

    h1{
        font-size: 18px;
    }

    h2{
        font-size: 14px;
    }

    h3{
        margin:8px 0;
        font-size: 12px;
    }

    .date-tag{
        font-size: 10px;
        min-width: 60px;
    }

    .mobile-menu-button{
        mask: url("/assets/icons/menu.svg");
        width: 24px;
        height: 24px;
        background: #fcaf17;
        mask-size: cover;
        cursor: pointer;
    }

    .bottom-container{
        padding: 8px !important;
    }

}

@media (max-width: 480px) {
    h2{
        font-size: 18px;
    }

    .s2{
        width: calc(16.66% - 4px) !important;
    }

    .s3{
        width: calc(25% - 5px) !important;
    }

    .s4{
        width: calc(33% - 4px) !important;
    }

    .s6{
        width: calc(50% - 4px) !important;  
    }
}
</style>
