<template>
    <nav>
        <div class="container">
            <div class="navbar">
                <div class="mobile-menu-button hide-on-large"></div>
                <div class="identity">
                    <router-link to="/" class="logo">
                        <img src="/assets/img/logo.svg" alt="Logo">
                    </router-link>
                    <div class="icons hide-on-small">
                        <a v-if="config.instagram_link" :href="config.instagram_link" target="blank" class="ig"></a>
                        <a v-if="config.facebook_link" :href="config.facebook_link" target="blank" class="fb"></a>
                    </div> 
                </div>    
                <div class="menu">
                    <router-link to="/" class="hide-on-small">Inicio</router-link>
                    <router-link to="/noticias" class="hide-on-small">Noticias</router-link>
                    <router-link to="/cartelera" class="hide-on-small">Cartelera</router-link>
                    <router-link to="/proximamente" class="hide-on-small">Próximamente</router-link>
                    <router-link to="/trailers" class="hide-on-small">Trailers</router-link>
                    
                    <router-link to="/buscar" class="hide-on-small icon">
                        <div class="search-icon"></div>
                    </router-link>

                    <a href="https://clientes.enelcine.com.ar" class="button">
                        <div class="button-icon"></div>
                        <span class="hide-on-small">Clientes</span>
                    </a>
                </div>
            </div>
        </div>    
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
    mounted() {
        // get config
        (async () => {
            this.config = await this.getConfig()
        })()

        var menuButtonOpen = document.querySelector('.mobile-menu-button');
        var menuButtonClose = document.querySelector('.mobile-menu-close');
        var menu = document.querySelector('.mobile-menu');

        menuButtonOpen.addEventListener('click', function(){
            menu.classList.remove('closed')
        });

        menuButtonClose.addEventListener('click', function(){
            menu.classList.add('closed')
        });
    }
}
</script>

<style scoped>
nav{
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    background: #212121;
    position: fixed;
    z-index: 2;
}

nav .container{
    height: 100%;
    padding: 0 32px;
}

.navbar{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.navbar .identity{
    display: flex;
    height: 100%;
    align-items: center;
}


.logo img{
    height: 53px;
    margin-right: 24px;
}

.icons{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icons *{
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.icons .ig{
    background: #fcaf17;
    mask: url("/assets/img/instagram-brands.svg");
    mask-size: 18px;
    mask-position: center;
    mask-repeat: no-repeat;
}

.icons .fb{
    background: #fcaf17;
    mask: url("/assets/img/facebook.svg");
    mask-size: 18px;
    mask-position: center;
    mask-repeat: no-repeat;
}


.icons .tw{
    background: #fcaf17;
    mask: url("/assets/img/twitter.svg");
    mask-size: 18px;
    mask-position: center;
    mask-repeat: no-repeat;
} 

.icons .logo img{
    height: 52px;
}

.navbar .menu{
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
}

.navbar .menu a{
    margin-left: 2rem;
    height: 100%;
    line-height: 80px;
    color: #fcaf17;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
}

/*.navbar .menu a.icon{
    display: flex;
    align-items: center;
    border-top: solid 6px transparent;
}*/

.navbar .menu a:hover:not(.button){
    border-bottom: solid 6px #fcaf17;
}

.navbar .button{
    padding: 6px 12px;
    height: 35px !important;
    line-height: 35px !important;
    text-align: center;
    align-items: center;
    border-radius: 32px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 700;
    border:none;
    color: black !important;  
    background-color: #fcaf17;
    display: flex;
}

.navbar .search-icon{
    background: #fcaf17;
    mask: url("/assets/img/search-magnifier.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    width: 18px;
    height: 18px;
    margin-top: 40px;
    transform:translateY(-50%);
}

.navbar .button .button-icon{
    background: black;
    mask: url("/assets/icons/user.svg");
    mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
}

.navbar .button span{
    margin-left: 8px;
}


@media (max-width: 768px){

    nav{
        height: 54px;
    }

    .navbar .logo{
        display: inherit;
    }

    .navbar .logo img{
        height: 36px;
         margin-right: 0 !important;
    }
    
    .navbar .button{
        margin-left: 0 !important;
        padding: 6px 10px;
    }
}



</style>