<template>
    <section class="footer dark">
        <div class="container">
            <div class="row">
                <div class="col-6 hide-on-small">
                    <div class="footer-links">
                        <h4>Links</h4>
                        <router-link to="/" class="hide-on-small">Inicio</router-link>
                        <router-link to="/noticias" class="hide-on-small">Noticias</router-link>
                        <router-link to="/estrenos" class="hide-on-small">Estrenos</router-link>
                        <router-link to="/proximamente" class="hide-on-small">Próximamente</router-link>
                        <router-link to="/trailers" class="hide-on-small">Trailers</router-link>
                    </div>
                </div>
                <!--div class="col-6 hide-on-small">   
                    <div class="footer-links">          
                        <h4>Empresa</h4>
                        <a href="">Nosotros</a>
                        <a href="">Servicios</a>
                        <a href="">Publicidad</a>
                    </div>           
                </div-->
                <div class="col-6 hide-on-small">   
                    <div class="footer-links">          
                        <h4>Social</h4>
                        <a v-if="config.instagram_link" :href="config.instagram_link" target="blank">Instagram</a>
                        <a v-if="config.facebook_link" :href="config.facebook_link" target="blank">Facebook</a>
                    </div>           
                </div>
                <div class="col-6 hide-on-small">   
                    <div class="footer-links">          
                        <h4>Contacto</h4>
                        <a v-if="config.email_contacto" :href="'mailto:'+config.email_contacto" target="blank">{{config.email_contacto}}</a>
                    </div>           
                </div>
                <div class="col-6">     
                    <img class="logo" src="/assets/img/logo.svg" alt="">    
                    <a>© 2021 Cine Integral S.A</a>
                    <a>Derechos Reservados</a>    
                </div>
            </div> 
        </div>     
    </section>
</template>

<script>
export default {
    name:'Footer',
    mounted(){
        (async () => {
            this.config = await this.getConfig()
        })()
    }
}
</script>

<style scoped>
    /*-----FOOTER-----*/

.footer .row{
    justify-content: flex-start;
}

.footer .col-6{
    margin-right: 128px;
}

.footer .col-6:last-child{
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
}

.footer .logo{
    margin-bottom: 16px;
}

.footer-links{
    display: flex;
    flex-direction: column;
}

.footer .footer-links *{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 400;
}

.footer .footer-links h4{
    color: #fcaf17;
    font-weight: 700;
    margin-bottom: 16px;
}

.footer a{
    text-decoration: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
}    

@media(max-width: 768px){
    .footer .row{
        justify-content: center;
    }

    .footer .col-6:last-child{
        margin: 0;
    }

}
</style>
