<template>
    <div>
        <router-link :to="/noticias/ + item.slug" class="newsItem" v-if="item">
            <div class="img" :style="{ backgroundImage: 'url(' + item.portada.data.thumbnails[3].url + ')' }"></div>
            <h3>{{item.titulo}}</h3>
            <span class="date-tag">{{item.creado}}</span>  
        </router-link>

        <div class="newsItem" v-else>
            <div class="img loading"></div>
            <div class="h3-placeholder loading"></div>
            <div class="h3-placeholder loading"></div>
            <div class="h3-placeholder loading"></div>
            <span class="date-tag loading"></span> 
        </div>
    </div>        
</template>

<script>
export default {
    name:'NewsItem',
    props:{
        item: Object
    },
}
</script>

<style scoped>
.newsItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
}

.newsItem .img{
    border-radius: 4px;
    padding-top: 56.26%;
    background-color: #cfcfcf;
    background-position: center;
    background-size: cover;
}

.newsItem .img.loading{
    margin-bottom: 16px !important;
}

.newsItem h3{
    display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.newsItem .h3-placeholder{
    width: 100%;
    height: 20px;
    margin-bottom: 8px;
}

.newsItem .h3-placeholder:nth-child(4){
    width: 50% !important;
}

.newsItem .date-tag.loading{
    margin-top: 16px !important;
}
</style>