import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// plyr
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
    plyr: {
        controls: ['play-large', 'play', 'mute', 'volume', 'fullscreen'],
        resetOnEnd: true,
        //autoplay: true,
        //muted: true,
        hideControls: false
    }
})

Vue.config.productionTip = true

Vue.mixin({
    data() {
        return {
            server: 'https://admin.enelcine.com.ar/api',
            config: Promise
        }
    },
    methods: {
        getConfig: async function () {
            const config = await this.getItem('home')
            return config
        },
        getItems: async function (category, limit = null, filter = null, date = null) {
            const thisThu = this.getThu('this')
            let endpoint

            endpoint = '/items/' + category + '?fields=*'

            switch (category) {
                case 'noticias':
                    endpoint += ',portada.*,pelicula_relacionada.*,categoria.nombre&sort=-creado'
                    break

                case 'peliculas':
                    endpoint += ',portada.*,poster.*,genero.generos_id.nombre'
                    break

                case 'top_5':
                    endpoint += ',pelicula.*,pelicula.portada.*,pelicula.poster.*'
                    break
            }

            if (filter !== null) {
                endpoint += '&' + filter
            }

            if (limit !== null) {
                endpoint += '&limit=' + limit
            }

            let response = await this.axios.get(this.server + endpoint).catch(error => {
                console.log(error.response)
                router.push('/disconnected')
                throw 'Error de Servidor'
            })

            let items = response.data

            if (category == "noticias") {
                var news = []
                news.data = []
                news.meta = items.meta

                items.data.forEach((newsItem, index) => {
                    if (newsItem.portada !== null && newsItem.titulo !== null && newsItem.creado !== null) {
                        items.data[index].creado = this.getElapsedTime(newsItem.creado)
                        news.data.push(items.data[index])
                    }
                })

                items = news
            }

            if (category == "peliculas") {
                var movies = []
                movies.data = []
                movies.meta = items.meta

                items.data.forEach((movie, index) => {
                    if (movie.titulo_local !== null) {
                        if (movie.fecha_local !== null) {
                            items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)

                            if (this.getFormatedDate(thisThu) == movie.fecha_local) {
                                items.data[index].estreno = true
                            } else {
                                items.data[index].estreno = false
                            }
                        } else {
                            items.data[index].fecha_local = "A Definir"
                        }

                        movies.data.push(items.data[index])
                    }
                })

                items = movies
            }

            return items
        },

        getItem: async function (category, slug) {
            let endpoint

            switch (category) {
                case 'noticias':
                    endpoint = '/items/noticias?fields=*,portada.*,pelicula_relacionada.*&filter[slug]=' + slug
                    break
                case 'peliculas':
                    endpoint = '/items/peliculas?fields=*,portada.*,poster.*,distribuidora.*,genero.generos_id.*&filter[slug]=' + slug
                    break
                case 'home':
                    endpoint = '/items/home?fields=*.*.*'
                    break
            }

            const response = await this.axios.get(this.server + endpoint).catch(error => {
                console.log(error.response)
                router.push('/disconnected')
                throw 'Error de Servidor'
            })

            let item

            if (!response.data.data[0]) {
                return item = []
            } else {
                item = await response.data.data[0]

                if (item.portada === null) {
                    item.portada = this.config.imagen_no_disponible
                }

                if (category == "peliculas") {
                    item.titulo = (item.titulo_local != null) ? item.titulo_local : item.titulo_original

                    if (item.fecha_local !== null) {
                        item.fecha_local = this.getFormatedDate(item.fecha_local)
                    } else {
                        item.fecha_local = "A Definir"
                    }
                }

                if (category == "noticias") {
                    item.fecha = this.getElapsedTime(item.creado)
                }

                return item

            }
        },
        getElapsedTime: function (date) {
            date = date.replace(' ', 'T') + 'Z';
            var prevTime = new Date(date)
            var thisTime = new Date()
            var timeDiff = thisTime.getTime() - prevTime.getTime()
            timeDiff /= 1000;
            timeDiff = Math.round(timeDiff);

            var time

            if (timeDiff > 31536000) {
                timeDiff = Math.round(timeDiff / 31536000)
                time = (timeDiff <= 1) ? 'año' : 'años'
            } else if (timeDiff > 2629750) {
                timeDiff = Math.round(timeDiff / 2629750)
                time = (timeDiff <= 1) ? 'mes' : 'meses'
            } else if (timeDiff > 604800) {
                timeDiff = Math.round(timeDiff / 604800)
                time = (timeDiff <= 1) ? 'semana' : 'semanas'
            } else if (timeDiff > 86400) {
                timeDiff = Math.round(timeDiff / 86400)
                time = (timeDiff <= 1) ? 'día' : 'días'
            } else if (timeDiff > 3600) {
                timeDiff = Math.round(timeDiff / 3600)
                time = (timeDiff <= 1) ? 'hora' : 'horas'
            } else if (timeDiff > 60) {
                timeDiff = Math.round(timeDiff / 60)
                time = (timeDiff <= 1) ? 'minuto' : 'minutos'
            }

            return 'Hace ' + timeDiff + ' ' + time
        },
        getFormatedDate: function (date) {
            date = new Date(date + 'T12:00:00.000Z')
            date = new Intl.DateTimeFormat('es-ES', { dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires' }).format(date)
            return date
        },
        countViews: function (collection, id) {
            let endpoint = '/custom/count-views'
            this.axios.post(this.server + endpoint, { collection: collection, id: id })
        },
        nextSlide: function (swiper) {
            swiper.$swiper.slideNext()
        },
        prevSlide: function (swiper) {
            swiper.$swiper.slidePrev()
        },
        getThu: function () {
            // set dates
            const today = new Date()
            let thisThu = new Date()
            const thuNmb = 4
            let todayNmb = today.getDay()

            // set number for sunday
            if (todayNmb == 0) {
                todayNmb = 7
            }

            // if not default day number
            if (todayNmb != 4) {
                const diff = (todayNmb > thuNmb) ? todayNmb - thuNmb : thuNmb + (todayNmb - 1)
                thisThu.setDate(today.getDate() - diff)
            } else {
                thisThu = today
            }

            thisThu = this.getStringDate(thisThu)

            return thisThu
        },
        getStringDate: function (date) {
            const dd = String(date.getDate()).padStart(2, '0')
            const mm = String(date.getMonth() + 1).padStart(2, '0')
            const yyyy = date.getFullYear()
            const formattedDate = yyyy + '-' + mm + '-' + dd

            return formattedDate
        },
        setHeader: function (type, item) {
            let title
            let image
            let description
            const url = window.location

            title = item.titulo

            if (type == 'noticias') {
                image = item.portada.data.full_url
                description = item.contenido.substring(0, 120).replace('<p>', '')
            } else if ('peliculas' && item.sinopsis != null) {
                description = item.sinopsis.substring(0, 120).replace('<p>', '')
            } else {
                description = '¡Encontrá toda la información actualizada del cine en Argentina!'
                image = window.location.protocol + '//' + window.location.hostname + '/assets/img/cover.jpg'

            }

            // Primary Tags
            document.querySelector('head title').textContent = title + ' - EnElCine'
            document.querySelector('[name="description"]').setAttribute('content', description)

            // Facebook
            document.querySelector('[property="og:image"]').setAttribute('content', image)
            document.querySelector('[property="og:title"]').setAttribute('content', title + ' - EnElCine')
            document.querySelector('[property="og:url"]').setAttribute('content', url)
            document.querySelector('[property="og:description"]').setAttribute('content', description)

            // Twitter
            document.querySelector('[property="twitter:image"]').setAttribute('content', image)
            document.querySelector('[property="twitter:title"]').setAttribute('content', title + ' - EnElCine')
            document.querySelector('[property="twitter:url"]').setAttribute('content', url)
            document.querySelector('[property="twitter:description"]').setAttribute('content', description)
        }
    }
})

new Vue({
    router,
    render: function (h) { return h(App) }
}).$mount('#app')