<template>
    <div>
        <div class="top5item" v-if="movie.pelicula">
            <router-link :to="/peliculas/ + movie.pelicula.slug" class="top5ItemContainer">
                <div class="poster" :style="{ backgroundImage: 'url(' + movie.pelicula.poster.data.thumbnails[5].url + ')' }"></div>
                <div class="number">{{number}}</div>
            </router-link>
            <div class="atte">
                <h2>{{movie.espectadores}}</h2>
                <h4>ESPECTADORES</h4>
            </div>
        </div>
        <div class="top5item" v-else>
            <div class="top5ItemContainer">
                <div class="poster"></div>
                <div class="number">{{number}}</div>
            </div>
            <div class="atte">
                <h2>-</h2>
                <h4>ESPECTADORES</h4>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'top5Item',
    props: {
        number: {
            type: Number,
            default: 0
        },
        atte: {
            type: Number,
            default: 0
        },
        movie: {
            type: Object
        }
  }
}
</script>

<style scoped>
   
   .top5item{
        width: 100%;
    }

    .top5item h2{
        margin-bottom: 0 !important;
        font-size: 36px;
    }

    .top5item h4{
        margin-top: 0 !important;
        font-size: 14px;
    }

    .top5item .atte{
        width: 80%;
        text-align: center;
        margin-top: 4px;
    }

    .top5ItemContainer{
        wIdth: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
    }
    
    .top5ItemContainer .number{
        font-size: 150px;
        font-family: 'Roboto', sans-serif;
        color: #fcaf17;
        text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.25);
        font-weight: 700;
        transform: translateX(-50%);
    }

    .poster{
        width: 100%;
        padding-top: 142.8%;
        background: #cfcfcf;
        border-radius: 4px;
        position: relative;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
    } 

    @media (max-width: 768px) {
        
        .top5item h2{
            margin-bottom: 0 !important;
            font-size: 18px;
        }

        .top5item h4{
            margin-top: 0 !important;
            font-size: 10px;
        }

        .top5ItemContainer .number{
            font-size: 100px;
            font-family: 'Roboto', sans-serif;
            color: #fcaf17;
            text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.25);
            font-weight: 700;
            transform: translateX(-50%);
        }
    
    }
</style>
