import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Inicio',
        component: Inicio
    },
    {
        path: '/trailers',
        name: 'Trailers',
        component: function() {
            return import ('../views/Trailers.vue')
        }
    },
    {
        path: '/noticias',
        name: 'Noticias',
        component: function() {
            return import ('../views/Noticias.vue')
        }
    },
    {
        path: '/:category/:slug',
        name: 'Single',
        component: function() {
            return import ('../views/Single.vue')
        }
    },
    {
        path: '/proximamente',
        name: 'Proximamente',
        component: function() {
            return import ('../views/Proximamente.vue')
        }
    },
    {
        path: '/cartelera',
        name: 'Cartelera',
        component: function() {
            return import ('../views/Estrenos.vue')
        }
    },
    {
        path: "*",
        name: '404',
        component: function() {
            return import ('../views/404.vue')
        }
    },
    {
        path: "/disconnected",
        name: 'NoConnection',
        component: function() {
            return import ('../views/NoConnection.vue')
        }
    },
    {
        path: "/buscar",
        name: 'Buscador',
        component: function() {
            return import ('../views/Buscador.vue')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
})

export default router