<template>
  <div class="connection-lost">
    <div class="icon"></div>
    <h1>Lo sentimos mucho</h1>
    <h2>La conexión se ha perdido</h2>
    <div class="button" @click="retry()">Inténtelo de nuevo</div>
  </div>
</template>
<script>
  export default{
    name:'ConnectionLost',
    methods: {
      retry: function (){
        this.$router.back()
      }
    }
  }
</script>
<style scoped>
  .connection-lost{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  .connection-lost h1{
    color: black;
    text-align: center;
  }

  .icon {
    background: #aaaa;
    mask: url("/assets/img/no-wifi.svg");
    mask-size: 80px;
    width: 80px;
    height: 80px;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  .connection-lost h2, .icon{
    margin-bottom: 24px;
  }

  .button{
    padding: 6px 12px;
    height: 35px !important;
    line-height: 35px !important;
    text-align: center;
    align-items: center;
    border-radius: 32px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 700;
    border:none;
    color: black !important;  
    background-color: #fcaf17;
    display: flex;
    cursor: pointer;
  }

</style>