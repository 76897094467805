<template>
    <div class="mobile-menu closed">
        <div class="mobile-menu-header">
            <div class="mobile-menu-close"></div>
        </div>
        <div class="mobile-menu-body">
            <router-link to="/">Inicio</router-link>
            <router-link to="/noticias">Noticias</router-link>
            <router-link to="/cartelera">Cartelera</router-link>
            <router-link to="/proximamente">Próximamente</router-link>
            <router-link to="/trailers">Trailers</router-link>
            <router-link to="/buscar">Buscar</router-link>
            <a href="https://clientes.enelcine.com.ar">Acceso Clientes</a>
        </div>
        <div class="mobile-menu-footer">
            <div class="logo">
                <img src="/assets/img/logo.svg" alt="Logo">
            </div>
            <div class="icons">
                <a v-if="config.instagram_link" :href="config.instagram_link" target="blank" class="ig"></a>
                <a v-if="config.facebook_link" :href="config.facebook_link" target="blank" class="fb"></a>
                <a v-if="config.email_contacto" :href="config.email_contacto" target="blank" class="email"></a>
            </div>     
        </div>
    </div>
</template>

<script>

export default{
    name:'MobMenu',
    mounted(){
        (async () => {
            this.config = await this.getConfig()
        })()

        var menu = document.querySelector('.mobile-menu');
        var menuMenuLinks = document.querySelectorAll('.mobile-menu-body a');

        menuMenuLinks.forEach(function(link){
            link.addEventListener('click', function(){
                menu.classList.add('closed')
            })
        })
    }
}

</script>

<style scoped>
    
    .mobile-menu{
        width: 300px;
        background: #212121;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px 16px;
        transition: 300ms;
    }

    .mobile-menu.closed{
        transform: translateX(-300px);
    }

    .container{
        padding: 16px !important;
        width: 100%;
        height: 100%;
    }

    .mobile-menu-header{
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .mobile-menu-header .mobile-menu-close{
        background: #fcaf17;
        mask: url("/assets/img/cancel.svg");
        mask-position: center;
        mask-repeat: no-repeat;
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    .mobile-menu-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 8;
        overflow: auto;
    }

    
    .mobile-menu-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        flex: 1;
    }


    .img{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }


    .logo{
        width: 96px;
        height: 53px;
        margin-bottom: 16px;
    }

    .icons{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 75%;
    }

    .icons *{
        width: 24px;
        height: 24px;

    }

    .icons .ig{
        background: #fcaf17;
        mask: url("/assets/img/instagram-brands.svg");
        mask-size: 18px;
        mask-position: center;
        mask-repeat: no-repeat;
    }

    .icons .fb{
        background: #fcaf17;
        mask: url("/assets/img/facebook.svg");
        mask-size: 18px;
        mask-position: center;
        mask-repeat: no-repeat;
    }


    .icons .tw{
        background: #fcaf17;
        mask: url("/assets/img/twitter.svg");
        mask-size: 18px;
        mask-position: center;
        mask-repeat: no-repeat;
    }

    .icons .email{
        background: #fcaf17;
        mask: url("/assets/img/envelope.svg");
        mask-size: 18px;
        mask-position: center;
        mask-repeat: no-repeat;
    }


    .icons .logo{
        width: 96px;
        height: 53px;
        background: #fcaf17;
        mask: url("/assets/img/logo.svg");
    } 

    .mobile-menu a{
        line-height: 64px;
        border-bottom: solid 1px #666;
        color: #fcaf17;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 700;
        text-decoration: none;
        box-sizing: border-box;
    }

    .mobile-menu a:last-child{
        border: none;
    }
    
</style>
