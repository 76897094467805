<template>
	<div class="home">
		<!----- FEATURED NEWS ----->
		<section class="dark">
			<div class="container">
				<div class="blocks-container" v-if="featuredNews.data && featuredNews.data.length > 0 && !isLoading">
					<router-link :to="/noticias/ + newsItem.slug" class="block" v-for="newsItem in featuredNews.data" :key="newsItem.id" >
						<div class="news-item" :style="{ backgroundImage: 'url(' + newsItem.portada.data.thumbnails[5].url + ')' }">
							<div class="news-item-content">
								<span class="date-tag">{{newsItem.creado}}</span>
								<h2>{{newsItem.titulo}}</h2>
							</div>    
						</div>
					</router-link>
				</div>

				<!--SKELETON(NOTICIAS DEL INICIO)-->
				
				<div class="blocks-container" v-else >
					<div class="block" v-for="(n,index) in 5" :key=index >
						<div class="news-item loading"> 
						</div>
					</div>
				</div>
			</div>      
		</section>

		<section v-if="isLoading">
			<Spinner />
		</section>

		<!----- TOP 5 ----->
		<section class="top-5" v-if="top5.data && top5.data.length > 0 && !isLoading">
			<div class="container">
				<div class="section-header">
					<h2 class="section-title">Lo más visto</h2>
					<div class="swiper-navigation">
						<div class="swiper-button-prev" @click="prevSlide($refs.top5Swiper)"></div>
						<div class="swiper-button-next" @click="nextSlide($refs.top5Swiper)"></div>
					</div> 
				</div>
				<swiper class="row" ref="top5Swiper" :options="top5Swiper">
					<swiper-slide v-for="(movieItem, movieItemIndex) in top5.data" :key=movieItemIndex >
						<Top5Item :number="setTopNumber(movieItemIndex)" :movie="movieItem"/>
					</swiper-slide>
				</swiper>
			</div>
		</section>
		
		<!--BANNERS-->
		<section class="banners" v-if="bannersTop.length > 0 && !isLoading">
			<div class="container">
				<a :href="banner.link" target="blank" class="banner" v-for="banner in bannersTop" :key="banner.id" :style=" { backgroundImage: 'url(' + getTargetBanner(banner) + ')'}"></a>
			</div>
		</section>

		<!----- NOTICIAS ----->
		<div v-if="news.length > 0 && !isLoading">
			<section class="news" v-for="(section, index) in news" :key=index>
				<div class="container">
					<div class="section-header">
						<router-link :to="'/noticias/?filter=' + section.nombre" >
							<h2 class="section-title">{{section.nombre}}</h2>
						</router-link>
						<div class="swiper-navigation">
							<div class="swiper-button-prev" @click="prevSlide($refs.newsSwiper[index])"></div>
							<div class="swiper-button-next" @click="nextSlide($refs.newsSwiper[index])"></div>
						</div>
					</div>
					<swiper class="row" ref="newsSwiper" :options="NewsSwiperOptions" v-if="news.length > 0">
						<swiper-slide v-for="newsItem in section.items.data" :key="newsItem.id">
							<NewsItem :item="newsItem" />
						</swiper-slide>
					</swiper>
				</div>
			</section>
		</div>

		<!--BANNERS-->
		<section class="banners" v-if="bannersBottom.length > 0 && !isLoading">
			<div class="container">
				<a :href="banner.link" target="blank" class="banner" v-for="banner in bannersBottom" :key="banner.id" :style=" { backgroundImage: 'url(' + getTargetBanner(banner) + ')'}"></a>
			</div>
		</section>

		<!----- TRAILERS ----->
		<section class="trailers dark" v-if="trailers.data && trailers.data.length > 0 && !isLoading">
			<div class="container">
				<div class="section-header">
					<router-link to="/trailers" >
						<h2 class="section-title">Trailers</h2>
					</router-link>
					<div class="swiper-navigation">
						<div class="swiper-button-prev" @click="prevSlide($refs.trailersSwiper)"></div>
						<div class="swiper-button-next" @click="nextSlide($refs.trailersSwiper)"></div>
					</div> 
				</div>    
				<swiper class="row" ref="trailersSwiper" :options="TrailersSwiperOptions">
					<swiper-slide v-for="movie in trailers.data" :key="movie.id">
						<div class="poster">
							<MovieItem :showPlay="true" :showTitle="false" :showDateTag="false" :movie="movie" />
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</section>

		<!----- AHORA EN CINES ----->
		<section class="movies" v-if="nowPlaying.data && nowPlaying.data.length > 0 && !isLoading">
			<div class="container">
				<div class="section-header">
					<router-link to="/cartelera" >
						<h2 class="section-title">Ahora en cines</h2>
					</router-link>
					<div class="swiper-navigation">
						<div class="swiper-button-prev" @click="prevSlide($refs.nowPlayingSwiper)"></div>
						<div class="swiper-button-next" @click="nextSlide($refs.nowPlayingSwiper)"></div>
					</div>
				</div>    
				<swiper class="row" ref="nowPlayingSwiper" :options="movies">
					<swiper-slide v-for="movie in nowPlaying.data" :key="movie.id">
						<MovieItem :showPlay="false" :showTitle="true" :showDateTag="true" :movie="movie" />
					</swiper-slide>
				</swiper>
			</div>
		</section>

		<!----- PRÓXIMAMENTE ----->
		<section class="movies" v-if="comingSoon.data && comingSoon.data.length > 0 && !isLoading">
			<div class="container">
				<div class="section-header">
					<router-link to="/proximamente" >
						<h2 class="section-title">Próximamente</h2>
					</router-link>
					<div class="swiper-navigation">
						<div class="swiper-button-prev" @click="prevSlide($refs.ComingSoonSwiper)"></div>
						<div class="swiper-button-next" @click="nextSlide($refs.ComingSoonSwiper)"></div>
					</div>
				</div>    
				<swiper class="row" ref="ComingSoonSwiper" :options="movies">
					<swiper-slide v-for="movie in comingSoon.data" :key="movie.id">
						<MovieItem :showPlay="false" :showTitle="true" :showDateTag="true" :movie="movie" />
					</swiper-slide>
				</swiper>
			</div>
		</section>

		<div class="full-page-banner" v-if="bannerFull.length > 0 && showBannerFull">
			<div class="banner" :style=" { backgroundImage: 'url(' + getTargetBanner(bannerFull[0]) + ')'}">
				<div class="banner-close" @click="closeBanner">
					<div class="banner-close-icon"></div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import NewsItem from '@/components/NewsItem.vue';
import MovieItem from '@/components/MovieItem.vue';
import Top5Item from '@/components/Top5Item.vue';
import ConnectionLost from '@/components/ConnectionLost.vue';

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'

export default {
name: "Inicio",
components: {
	Spinner,
	NewsItem,
	MovieItem,
	Top5Item,
	Swiper,
	SwiperSlide,
	ConnectionLost
},
data(){
	return{
			isLoading: true,
			bannersTop: [],
			bannersBottom: [],
			bannerFull: [],
			showBannerFull: false,
			nowPlaying: [],
			comingSoon: [],
			featuredNews: [],
			trailers: [],
			top5: [],
			news: [],
			top5Swiper: {
				slidesPerView: 3.1,
				spaceBetween: 8,
				grabCursor: true,
				simulateTouch: true,
				freeMode: true,
				freeMode: {
					minimunVelocity: 0.03,
					momentum: true
				},
				breakpoints: {
					768: {
						slidesPerView: 4.1,
						spaceBetween: 8
					},
					1024: {
						slidesPerView: 5,
						spaceBetween: 32
					}
				}
			},
			NewsSwiperOptions: {
				slidesPerView: 2.1,
				spaceBetween: 8,
				observer: true,
				freeMode: true,
				freeMode: {
					minimunVelocity: 0.04,
					momentum: true
				},
				breakpoints: {
					768: {
						slidesPerView: 3.1,
						spaceBetween: 16
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 20
					}
				}
			},
			TrailersSwiperOptions: {
				slidesPerView: 2.1,
				spaceBetween: 8,
				freeMode: true,
				freeMode: {
					minimunVelocity: 0.03,
					momentum: true
				},
				breakpoints: {
					768: {
						slidesPerView: 3.1,
						spaceBetween: 16
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 20
					}
				}
			},
			movies: {
				slidesPerView: 3.1,
				spaceBetween: 8,
				grabCursor: true,
				simulateTouch: true,
				freeMode: true,
				freeMode: {
					minimunVelocity: 0.03,
					momentum: true
				},
				breakpoints: {
					768: {
						slidesPerView: 4.1,
						spaceBetween: 8
					},
					1024: {
						slidesPerView: 6,
						spaceBetween: 20
					}
				}
			},
	}
},
directives: {
swiper: directive
},
mounted (){ 
(async () => {
	const category = this.$route.params.category

	this.setHeader('page', {
		titulo: 'Inicio'
	})

	this.loadHome()
})()
},
methods: {
	loadHome: async function(){
		// get thu
		const thisThu = this.getThu()

		console.log(thisThu)

		// reset vars
		this.featuredNews = []
		this.news = []
		this.top5 = []
		this.trailers = []

		// get config
		this.config = await this.getConfig()

		// get banners
		if(this.config.banners.length > 0){
			this.config.banners.forEach( (banner) =>{
				if(banner.tipo == 'normal'){
					if(this.bannersTop.length < 2){
						this.bannersTop.push(banner)
					}else{
						this.bannersBottom.push(banner)
					}
				}else{
					this.showBannerFull = true
					this.bannerFull.push(banner)
				}
			})
		}

		// get featured news
		this.featuredNews = await this.getItems('noticias', 5)

		// display top 5
		if( this.config.mostrar_top_5 === true){
			this.top5 = await this.getItems('top_5')
		}

		// to exclude
		var exclude = ''
		this.featuredNews.data.forEach(item => {
			exclude+= item.id+','
		})

		// remove last comma
		exclude.substring(0, exclude.length - 1)

		// display news sections
		if( this.config.secciones.length > 0){
			this.config.secciones.forEach(item => {
				var newsSection = {}
				newsSection.id = item.id
				newsSection.nombre = item.nombre
				newsSection.items = {}
				this.news.push(newsSection)
			})
		}

		if(this.news.length > 0){
			this.news.forEach(async (item,index) => {
				this.news[index].items = await this.getItems('noticias',4,'filter[categoria][eq]='+item.id+'&filter[id][nin]='+exclude)
			})
		}

		// get trailers
		this.trailers = await this.getItems('peliculas', 4, 'filter[estado][eq]=publicado&filter[fecha_trailer][nnull]&filter[trailer][nempty]&sort=-fecha_trailer,-visualizaciones')

		//getnow playing
		this.nowPlaying = await this.getItems('peliculas', 6, 'filter[fecha_local][lte]='+thisThu+'&sort=-fecha_local,-visualizaciones')
		this.comingSoon = await this.getItems('peliculas', 6, 'filter[fecha_local][gt]='+thisThu+'&sort=fecha_local,-visualizaciones')        
		this.isLoading = false
	},
	setTopNumber: function(index){
			return index+1
	},
	getTargetBanner: function(banner){
		if(window.innerWidth > 768){
			return banner.desktop.data.full_url
		}else{
			return banner.mobile.data.full_url
		}
	},
	closeBanner: function(){
		this.showBannerFull = false
	}
}
};
</script> 

<style scoped>
.blocks-container{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
}

.block{
	position: relative;
	border-radius: 4px;
	overflow: hidden;
}

.block:nth-child(1), .block:nth-child(2){
	width: calc(50% - 12px);
	margin-bottom: 24px;
}

.block:nth-child(3), .block:nth-child(4), .block:nth-child(5){
	width: calc(33% - 8px);
}

.block .news-item{
	background: #cfcfcf;
	width: 100%;
	padding-top: 56.26%;
	flex-shrink: 0;
	background-size: cover;
	background-position: center;
	position: relative;
}

.block .news-item-content{
	width: 100%;
	padding: 16px;
	position: absolute;
	left: 0;
	bottom: 0;
	background-image: linear-gradient(to top,rgba(0,0,0,.7) 50%,rgba(0,0,0,0));
}

.block .news-item-content .date-tag{
	margin-bottom: 8px;
}

.block .news-item-content h2{
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.banners .container{
	display:flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.banners .banner{
	width: 640px;
	height: 100px;
	background-size: cover;
	background-position: center;
	background-color: #cfcfcf;
}

.full-page-banner{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
}

.full-page-banner .banner{
	width: 735px;
	height: 360px;
	position: relative;
	background: #cfcfcf;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

}

.banner-close{
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 8px;
	background: white;
	cursor: pointer;
}

.banner-close-icon{
	background: black;
	mask: url('/assets/img/cancel.svg');
	mask-size: 16px;
	height: 16px;
	width: 16px;
}

section.dark:first-child{
    margin-top: -16px;
}

@media (max-width: 1024px){

}

@media (max-width: 768px) {
	.banners .banner{
		width: 320px;
		height: 50px;
	}

	.banners .banner:first-child{
		margin-bottom: 16px;
	}

	.full-page-banner .banner{
		width: 320px;
		height: 600px;
	}
}

@media (max-width: 480px) {
	.block{
		width: 100% !important;
		margin-bottom: 8px !important;
	}
	
	.block:last-child{
		margin-bottom: 0px !important;
	}

	.block:nth-child(1) .news-item, .block:nth-child(2) .news-item{
		padding-top: 75% !important;
	}
}

</style>
